/* Styles for screens up to 768px width (mobile) */
@media (max-width: 768px) {
    .leftContainer {
      justify-content: center;
    }
  
    .rightContainer {
      padding: 2rem;
    }
  
    .text-section {
      text-align: center;
      margin-bottom: 2rem;
    }
  
    .slideContainer {
      margin-top: 2rem;
    }
  
    .embla__slide {
      padding: 1rem;
    }
    
    /* Adjust other styles as needed */
  }
  